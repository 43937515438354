<template>

    <footer class="grid-footer">

        <div class="inner-wrapper grid-wrapper">

            <footer-subscription class="subscription section"/>

            <footer-contact class="contact section"/>

            <div class="separator section"/>

            <h2 class="header section">
                <nuxt-link :to="{ name: 'index' }">
                    <img :src="require('@/../shared/assets/images/main-logo.svg')"
                         height="27"
                         width="fit-content"
                         alt="Scout & Nimble"
                         loading="lazy"/>
                </nuxt-link>
            </h2>

            <footer-links class="links section"/>

            <footer-social-section class="social section"/>
        </div>

        <a class="powered-by-netkodo" href="https://netkodo.com" target="_blank">
            Site built by Netkodo
        </a>
    </footer>

</template>

<script>
    import FooterSubscription from '@/components/footer/FooterSubscription'
    import FooterLinks from '@/components/footer/FooterLinks'
    import FooterContact from '@/components/footer/FooterContact.vue'
    import FooterSocialSection from '@/components/footer/FooterSocialSection.vue'

    export default {
        name: 'the-footer',
        components: {
            FooterSubscription,
            FooterLinks,
            FooterContact,
            FooterSocialSection
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/stylesheets/variables.scss';

    $sections: header, subscription, contact, links, social, separator;

    .grid-footer{
        font-family: 'MotivaSans';
        display: grid;
        grid-row-gap: 10px;
        color: $color-white;
        background: $footer-background;
        margin-bottom: -40px;

        .inner-wrapper {
            padding-top: 84px;
            padding-bottom: 82px;
            grid-column-gap: 54px;
            grid-row-gap: 30px;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: auto 10px 90px;
            grid-template-areas:
                'subscription subscription subscription subscription links links links links'
                'separator separator separator separator separator separator separator separator'
                'header header header contact contact contact contact social';
        }

        .section {
            @each $section in $sections {
                &.#{$section} {
                    grid-area: $section;
                }
            }

            &.header {
                margin: 0;
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                    width: fit-content;
                    filter: invert(98%) sepia(7%) saturate(10%) hue-rotate(39deg) brightness(106%) contrast(99%);
                }
            }

            &.separator {
                height: 40px;
                margin-bottom: 0;
                border-bottom: .5px solid $off-white !important;
                opacity: .43;
            }
        }

        .powered-by-netkodo {
            margin: 0 auto 10px;
            font-size: 1rem;
        }

        @media screen and (max-width: 1360px) {
            .inner-wrapper {
                grid-template-areas:
                    'subscription subscription subscription subscription links links links links'
                    'separator separator separator separator separator separator separator separator'
                    'header header header header header header header header'
                    'contact contact contact contact contact contact contact contact'
                    'social social social social social social social social';
            }

            .section {
                &.header, &.contact, &.social {
                    margin: 0 auto;
                }

                &.header {
                    margin-bottom: -30px;
                }

                &.contact, &.social {
                    margin-top: -30px;
                }
            }
        }

        @media screen and (max-width: $tablet-max) {
            .inner-wrapper {
                $horizontal-padding: 25px;
                padding: 46px $horizontal-padding 31px;
                width: calc(100% - #{$horizontal-padding * 2});
                grid-template-columns: 1fr;
                grid-template-rows: none;
                grid-row-gap: 0;
                grid-template-areas: 'header' 'subscription' 'links' 'contact' 'social';
            }

            .section {
                &.header, &.contact, &.social {
                   margin: 0 auto;
                }

                &.header {
                    padding-bottom: 26px;
                    text-align: center;

                    a {
                      width: 100%;
                    }

                    img {
                        width: 70%;
                    }
                }

                &.subscription {
                    padding-top: 26px;
                    padding-bottom: 39px;
                    padding-right: 0;
                    border-right: none;
                }

                &.contact {
                    padding-top: 42px;
                }

                &.links {
                    padding-top: 54px;
                    padding-bottom: 50px;
                }

                &.social {
                    padding: 10px 0;

                    ::v-deep ul {
                        flex-grow: 1;
                        justify-content: center;
                    }
                }

                &.separator {
                    display: none !important;
                }

                &.subscription, &.links {
                    border-bottom: .5px solid $off-white !important;
                }
            }
        }
    }
</style>
